import { Link } from 'gatsby'
import React from 'react'
import Helmet from 'react-helmet'
import { Text } from 'rebass'
import Layout from '../components/Layout'
import { Container } from '../components/styled'

const NotFoundPage = () => (
  <Layout>
    <Helmet>
      <title>404 - Página no encontrada</title>
      <meta name="description" content="Página no encontrada" />
    </Helmet>

    <Container>
      <Text as="h1" fontSize={5} mb={3}>
        Página no encontrada
      </Text>
      <Text>
        Por favor, regresa al <Link to="/">inicio</Link> o utiliza el menú para
        acceder a otra página.
      </Text>
    </Container>
  </Layout>
)

export default NotFoundPage
